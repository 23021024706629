.header {
	border-bottom: 1px solid var(--gray2);
	background-color: var(--gray);

	.logo {
		position: relative;
		margin: 0;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.btn-logout {
		cursor: pointer;
		color: var(--pink);
	}
}