.footer {
	padding: 2rem 0;
	color: var(--black);
	border-top: 1px solid var(--gray2);
	background-color: var(--gray);

	.repository {
		i {
			margin-right: .75rem;
			padding: 0.4rem;
			font-size: 1.2rem;
			color: var(--bs-red);
			border-radius: .25rem;
			background-color: var(--light-pink);
		}
	}

	.copyright {
		text-align: right;
	}
}