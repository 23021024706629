.login {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--pink);

	.container {
		max-width: 360px;
	}

	.form {
		padding: 2rem;
		border-radius: 12px;
		background-color: white;
		box-shadow: 0px 0px 30px 0px rgba(black, .2);
	}

	.logo {
		font-size: 3rem;
	}

	h2 {
		margin-bottom: 1.5rem;
		font-size: 1.25rem;
	}

	.show-pass {
		position: absolute;
		right: 0;
		top: 50%;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
		opacity: .8;
		cursor: pointer;
		transform: translate(-10px, -50%);
	}

	.actions {
		width: 110px;

		.btn {
			border-color: var(--gray2);
			background-color: var(--gray2);

			&:hover {
				border-color: var(--gray3);
				background-color: var(--gray3);
			}
		}
	}
}