html {
	font-family: var(--opensans);
	line-height: 1.4;
	font-size: 14px;
	font-weight: 400;
	background: white;
}

body {
	position: relative;
	min-height: 100vh;
	line-height: 1.4;
	font-size: 1em;
	font-weight: 400;
	color: var(--black);
}

body,
input,
select,
textarea,
button,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 1.5rem;
	font-weight: 700;

	a {
		color: inherit;
		text-decoration: none;
	}

	p+& {
		margin-top: 40px;
	}
}

a {
	color: inherit;
	transition: color 0.3s linear;

	&:hover {
		color: var(--blue);
	}
}

strong {
	font-weight: 700;
}

.container,
// .container-fluid,
// .container-lg,
// .container-md,
// .container-sm,
// .container-xl,
// .container-xxl,
.row {
	--bs-gutter-x: .5rem;
	--bs-gutter-y: 0;
}

.row {
	margin-right: calc(var(--bs-gutter-x, .5rem) * -1);
	margin-left: calc(var(--bs-gutter-x, .5rem) * -1);

	>.col,
	>[class*=col-] {
		padding-right: var(--bs-gutter-x, .5rem);
		padding-left: var(--bs-gutter-x, .5rem);
		// padding-top: var(--bs-gutter-x, .5rem);
		// padding-bottom: var(--bs-gutter-x, .5rem);
	}
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;

	>.col,
	>[class*=col-] {
		padding-right: 0;
		padding-left: 0;
	}
}

.container {
	min-width: 320px;
	max-width: 1070px;
}

.main {
	min-height: calc(100vh - 86px);
}

html:not(.no-login) .main {
	min-height: calc(100vh - 58px - 86px);
	padding-top: 40px;
	padding-bottom: 40px;
}

.section {
	&-title {
		margin-bottom: 1rem;
		font-size: 1rem;
		color: var(--section-title-color);
	}
}

label {
	font-weight: 700;
	user-select: none;
}

.btn-check:focus+.btn,
.btn:focus,
.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn:active:focus,
.show>.btn.dropdown-toggle:focus {
	outline: 0;
	box-shadow: none;
}

.btn-secondary {
	color: rgba(black, .5);
	background-color: var(--gray2);
	border-color: var(--gray2);

	&:hover {
		color: rgba(black, .6);
		background-color: var(--gray3);
		border-color: var(--gray3);
	}
}

.btn-primary {
	color: white;
	background-color: var(--blue);
	border-color: var(--blue);

	&:hover {
		background-color: var(--blue2);
		border-color: var(--blue2);
	}
}

.btn-pink {
	color: white;
	background-color: var(--pink);
	border-color: var(--pink);

	&:hover {
		color: white;
		background-color: var(--pink2);
		border-color: var(--pink2);
	}
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	+i {
		display: none;
	}
}

// body.swal2-shown {
//     padding-right: 0 !important;
// }

body.swal2-toast-shown .swal2-container {
    width: 280px;
}

.swal2-popup.swal2-toast {
	color: #000;

	&.swal2-icon {
		&-warning {
			border-top: 4px solid #ffc800;
			background-color: #fef9e8;

			.swal2-icon.swal2-warning {
				border-color: rgba(#ffc800, .5);
				color: #ffc800;
			}
		}

		&-error {
			border-top: 4px solid #fa3f3f;
			background-color: #fee0e0;
		}

		&-info {
			border-top: 4px solid #3fc3ee;
			background-color: #f4f6ff;

			.swal2-icon.swal2-info {
				border-color: rgba(#3fc3ee, .5);
				color: #3fc3ee;
			}
		}

		&-success {
			border-top: 4px solid #5ad363;
			background-color: #ebf9ec;

			.swal2-icon.swal2-success .swal2-success-ring {
				border: 0.25em solid rgba(#5ad363, .3);
			}

			.swal2-icon.swal2-success [class^=swal2-success-line] {
				background-color: #5ad363;
			}
		}

		&-question {
			border-top: 4px solid #c9dae1;

			// .swal2-icon.swal2-success .swal2-success-ring {
			// 	border: 0.25em solid rgba(#5ad363, .3);
			// }

			// .swal2-icon.swal2-success [class^=swal2-success-line] {
			// 	background-color: #5ad363;
			// }
		}
	}
}

.swal2-title {
    padding: 1rem 1rem 0;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--black);
}

.swal-custom-btn {
	width: 160px;

	&.swal-cancel {
		color: rgba(black, .5);
		border-color: var(--gray2);
		background-color: var(--gray2);

		&:hover {
			color: rgba(black, .6);
			border-color: var(--gray3);
			background-color: var(--gray3);
		}
	}

	&.swal-approve {
		color: white;
		border-color: var(--pink);
		background-color: var(--pink);

		&:hover {
			border-color: var(--pink2);
			background-color: var(--pink2);
		}
	}
}