:root {
	--opensans: 'Open Sans', sans-serif;
	--black: #262626;
	--gray: #f9f9f9;
	--gray2: #e8e8e8;
	--gray3: #d7d7d7;
	--red: #da3d43;
	--pink: #e83d6d;
	--pink2: #fc3c72;
	--blue: #2277e0;
	--blue2: #0653b2;
	--yellow: #f1a824;
	--light-pink: #ffe6e2;
}